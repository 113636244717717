import MainInfo from "../project-details/main-info/index";
import SideInfo from "../project-details/side-info/index";
export default {
  name: "public-profile",
  components: { MainInfo, SideInfo },
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
